import styled from "styled-components"
import { Link } from "gatsby"
import { Link as RLink } from "@reach/router"
import {
  space,
  color,
  flexbox,
  layout,
  grid,
  typography,
  border,
  position,
} from "styled-system"

export const Image = styled.img(color, layout, border, space)

export const Box = styled.div(color, layout, border, space, position)

export const Flex = styled.div(
  {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  color,
  position,
  flexbox,
  layout,
  space,
  border
)

export const Grid = styled.div({ display: "grid" }, color, grid, layout, space)

// Typography
export const Title = styled.h1(
  {
    fontFamily: "Recoleta",
    textAlign: "center",
    fontSize: "48px",
    fontWeight: "normal",
    textDecoration: props => (props.u ? "underline" : "none"),
  },
  color,
  space,
  typography,
  position,
  layout
)
export const Text = styled.p(
  {
    fontFamily: "Manrope",
    textAlign: "center",
    width: "100%",
  },
  color,
  space,
  typography,
  layout
)
export const Label = styled.span(
  {
    fontFamily: "Manrope",
    textTransform: props => (props.down ? "capitalise" : "uppercase"),
  },
  color,
  space,
  typography,
  layout
)

export const MenuLink = styled(Link)(
  {
    fontFamily: "Recoleta",
    textAlign: "center",
    fontSize: "48px",
    fontWeight: "normal",
    marginBottom: 40,
    transition: "all 300ms cubic-bezier(0.390, 0.575, 0.565, 1.000)",
    "&:hover": {
      color: "rgba(255,255,255,0.4)",
    },
  },
  color,
  space,
  typography,
  position
)

export const CommunityLink = styled(RLink)(
  {
    fontFamily: "Recoleta",
    textAlign: "center",
    fontSize: "48px",
    fontWeight: "normal",
    marginBottom: 40,
    transition: "all 300ms cubic-bezier(0.390, 0.575, 0.565, 1.000)",
    "&:hover": {
      color: "rgba(255,255,255,0.4)",
    },
  },
  color,
  space,
  typography,
  position
)

export const LoginLink = styled.span(
  {
    fontFamily: "Recoleta",
    textAlign: "center",
    fontSize: "48px",
    fontWeight: "normal",
    marginBottom: 40,
    transition: "all 300ms cubic-bezier(0.390, 0.575, 0.565, 1.000)",
    cursor: "pointer",
    padding: 0,
    "&:hover": {
      color: "rgba(255,255,255,0.4)",
    },
  },
  color,
  space,
  typography,
  position
)

export const FooterLink = styled(Link)(
  {
    fontFamily: "Manrope",
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "normal",
    marginBottom: 8,
    transition: "all 300ms cubic-bezier(0.390, 0.575, 0.565, 1.000)",
    color: "rgba(255,255,255,0.4)",
    "&:hover": {
      color: "white",
    },
  },
  color,
  space,
  typography,
  position
)

export const Input = styled.input(
  {
    fontFamily: "Manrope",
  },
  color,
  space,
  typography,
  border,
  layout
)

export const Button = styled.button(
  {
    outline: "none",
    padding: "12px 32px",
    backgroundColor: props => (props.bg ? props.bg : "transparent"),
    border: props =>
      props.outline ? `2px solid ${props.color}` : `2px solid transparent`,
    fontFamily: "Manrope",
    fontSize: 16,
    cursor: "pointer",
    borderRadius: 3,
    color: props => (props.color ? props.color : "black"),
    transition: "all 300ms cubic-bezier(0.390, 0.575, 0.565, 1.000)",
    "&:hover": {
      color: props => (props.hcolor ? props.hcolor : "black"),
      backgroundColor: props => (props.hbg ? props.hbg : "white"),
    },
  },
  space,
  color,
  typography,
  layout,
  position,
  border
)
